import styled from "styled-components";

export const SettlementMessagesList = styled.ol``;

export const SettlementMessage = styled.li`
  list-style: none;
  margin-bottom: 24px;
`;

export const HorizontalLayout = styled.div`
  display: flex;
  gap: 12px;
  margin-bottom: 8px;

  /* Needs to be more specific than the main content styling */
  &&& > * {
    margin: auto 0;
    padding: 0;
  }
`;
