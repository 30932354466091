import { JsonDataDownloadModel } from "models/dataDownload/dataDownloadModel";
import { isRejected } from "utils/promiseHelpers";

export type DataErrored = {
  isErrored: true;
};

export type DataSuccess<T> = {
  isErrored: false;
  data: T;
};

export type DataOrErrored<T> = DataErrored | DataSuccess<T>;

/**
 * An object whose values are DataOrErrored objects, used for a collection of data.
 */
export type DataOrErroredCollection = {
  [k: string]: DataOrErrored<unknown>;
};

/**
 * The object with the same keys as TData, but whose values are the data extracted from
 * the DataOrErrored/DataSuccess object.
 *
 * e.g. type TData
 * {
 *   data1: Success<T1>,
 *   data2: Success<T2>
 * }
 * maps to type DataExtract<TData>
 * {
 *   data1: T1,
 *   data2: T2
 * }
 *
 * We only define this for objects whose values are all DataSuccess<T> rather than DataErrored
 * since that is the situation where this type is useful.
 */
export type DataExtract<TData extends DataOrErroredCollection> = {
  [K in keyof TData]: TData[K] extends DataSuccess<infer U> ? U : never;
};

export const dataIsSuccess = <T>(
  dataOrErrored: DataOrErrored<T>
): dataOrErrored is DataSuccess<T> => !dataOrErrored.isErrored;

export const dataIsErrored = <T>(
  dataOrErrored: DataOrErrored<T>
): dataOrErrored is DataErrored => dataOrErrored.isErrored;

export const extractDataFromPromise = <TData>(
  dataPromise: PromiseSettledResult<JsonDataDownloadModel<TData>>
): DataOrErrored<TData[]> =>
  isRejected(dataPromise)
    ? {
        isErrored: true,
      }
    : {
        isErrored: false,
        data: dataPromise.value.data,
      };
