import { getSettlementMessagesJsonData } from "api/dataVisualisation/settlementMessages/settlementMessages";
import { getSystemPricesJsonData } from "api/dataVisualisation/systemSellAndBuyPrices/systemSellAndBuyPrices";
import { getSystemWarningsJsonData } from "api/dataVisualisation/systemWarnings/systemWarnings";
import LastUpdated from "components/components/LastUpdated/LastUpdated";
import PageMeta from "components/components/Meta/PageMeta";
import Events, { EventsData } from "components/homepage/Events/Events";
import SystemSellBuyPrices, {
  SystemSellBuyPriceData,
} from "components/homepage/SystemSellBuyPrices/SystemSellBuyPrices";
import useDataWithRefresh from "hooks/useDataWithRefresh";
import { SettlementMessageModel } from "models/detailedSystemPrices/settlementModels";
import DateFilterModel from "models/filterModels/dateFilterModel";
import { SystemPriceModel } from "models/systemSellAndBuyPrices/systemSellAndBuyPricesModel";
import { SystemWarningsModel } from "models/systemWarnings/systemWarningsModel";
import React, { useCallback } from "react";
import { DataOrErrored, extractDataFromPromise } from "utils/dataOrErrored";
import { addDaysToDate } from "utils/dateHelpers";
import { isRejected } from "utils/promiseHelpers";

export type HomepageData = {
  systemWarnings: DataOrErrored<SystemWarningsModel[]>;
  systemPrices: DataOrErrored<SystemPriceModel[]>;
  settlementMessages: DataOrErrored<SettlementMessageModel[]>;
};

const automaticRefreshIntervalInMinutes = 5;

const eventsDataSelector = (homepageData: HomepageData | null): EventsData =>
  homepageData
    ? {
        systemWarnings: homepageData.systemWarnings,
      }
    : null;

const systemSellBuyPricesDataSelector = (
  homepageData: HomepageData | null
): SystemSellBuyPriceData =>
  homepageData
    ? {
        systemPrices: homepageData.systemPrices,
        settlementMessages: homepageData.settlementMessages,
      }
    : null;

const Homepage: React.FC = () => {
  const fetchData = useCallback(async (date: Date): Promise<HomepageData> => {
    const dateFilterOneWeek = new DateFilterModel(
      addDaysToDate(date, -7),
      date
    );
    const dateFilterOneDay = new DateFilterModel(addDaysToDate(date, -1), date);

    const systemWarnings = getSystemWarningsJsonData(dateFilterOneWeek, "");
    const systemPrices = getSystemPricesJsonData(dateFilterOneDay);
    const settlementMessages = getSettlementMessagesJsonData(dateFilterOneDay);
    const results = await Promise.allSettled([
      systemWarnings,
      systemPrices,
      settlementMessages,
    ]);
    // If all API requests failed, then essentially the whole request failed and we want to show
    // an error rather than updating the data - handle this case separately
    if (results.every(isRejected)) {
      throw new Error("All homepage API requests failed.");
    }
    return {
      systemWarnings: extractDataFromPromise(results[0]),
      systemPrices: extractDataFromPromise(results[1]),
      settlementMessages: extractDataFromPromise(results[2]),
    };
  }, []);

  const {
    data: homepageData,
    requestStatus,
    lastRefreshedDate,
    triggerDataRefresh,
  } = useDataWithRefresh(fetchData, automaticRefreshIntervalInMinutes);

  return (
    <>
      <PageMeta
        title="Home page"
        description="New home page, only available on dev and test environments"
      />
      {/* Add the section classname (defined in the scss by bulma styles) so that the padding matches other pages */}
      <div className="section">
        <h1 data-test-id="summary-heading">Summary</h1>
        <LastUpdated
          triggerDataRefresh={triggerDataRefresh}
          lastRefreshedDate={lastRefreshedDate}
          requestStatus={requestStatus}
        />
        <Events
          requestStatus={requestStatus}
          data={eventsDataSelector(homepageData)}
        />
        <SystemSellBuyPrices
          requestStatus={requestStatus}
          data={systemSellBuyPricesDataSelector(homepageData)}
        />
      </div>
    </>
  );
};

export default Homepage;
