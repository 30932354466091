import {
  faArrowsToDot,
  faArrowsUpToLine,
  faArrowTrendDown,
  faExclamationTriangle,
  faPlugCircleExclamation,
  faSortAmountDown,
  faSquareMinus,
  faWater,
} from "@fortawesome/free-solid-svg-icons";
import Icon from "components/components/Icon/Icon";
import { IconSize } from "components/components/Icon/IconWrapper";
import ParagraphWithLineBreaks from "components/shared/ParagraphWithLineBreaks/ParagraphWithLineBreaks";
import React from "react";
import { DateFormat, formatDateTimeString } from "utils/dateHelpers";
import { processNewLinesAndSplit } from "utils/stringHelpers";

import {
  HorizontalLayout,
  IconWrapper,
  Title,
  EventInfo,
  Timestamp,
  StyledShowMoreText,
  EventItemContent,
  NewMessage,
} from "./style";

interface Props {
  id: string;
  name: string;
  timestamp: Date;
  description: string;
}

const iconMap = new Map([
  ["IT SYSTEMS OUTAGE", faPlugCircleExclamation],
  ["ELECTRICITY MARKET NOTICE", faArrowTrendDown],
  ["CAPACITY MARKET NOTICE", faArrowsUpToLine],
  ["HIGH RISK OF DEMAND CONTROL", faSortAmountDown],
  ["DEMAND CONTROL IMMINENT", faArrowsToDot],
  ["DEMAND FLEXIBILITY SERVICE", faWater],
  ["NEGATIVE RESERVE ACTIVE POWER MARGIN", faSquareMinus],
]);

const thresholdInMinutes = 30;

const EventItem: React.FC<Props> = ({ id, name, timestamp, description }) => {
  const icon = iconMap.get(name) ?? faExclamationTriangle;

  const ageInMilliseconds = new Date().getTime() - timestamp.getTime();
  const ageInMinutes = ageInMilliseconds / (1000 * 60);
  const isNew = ageInMinutes < thresholdInMinutes;

  const newMessageId = `new-message-${id}`;
  return (
    <EventItemContent
      aria-describedby={isNew ? newMessageId : undefined}
      isNew={isNew}
    >
      {isNew && <NewMessage id={newMessageId}>New event</NewMessage>}
      <HorizontalLayout>
        <IconWrapper>
          <Icon iconName={icon} size={IconSize.medium}></Icon>
        </IconWrapper>
        <EventInfo>
          <Title>{name}</Title>
          <Timestamp>
            {formatDateTimeString(timestamp, DateFormat.DateTime)}
          </Timestamp>
          <StyledShowMoreText>
            <ParagraphWithLineBreaks
              lines={processNewLinesAndSplit(description)}
            />
          </StyledShowMoreText>
        </EventInfo>
      </HorizontalLayout>
    </EventItemContent>
  );
};

export { EventItem };
