import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons/faExclamationTriangle";
import Icon from "components/components/Icon/Icon";
import { IconSize } from "components/components/Icon/IconWrapper";
import InformationTooltip from "components/components/InformationTooltip/InformationTooltip";
import { RequestStatus } from "hooks/useRequest";
import React, { ReactElement } from "react";
import colours from "styles/colours";
import {
  DataOrErroredCollection,
  dataIsErrored,
  DataSuccess,
  DataExtract,
} from "utils/dataOrErrored";

import {
  ErrorWrapper,
  Header,
  HorizontalLayout,
  MinHeight,
  PanelWithVerticalMargins,
  StyledLink,
  Title,
  FixedSizeLoadingSpinner,
} from "./style";

interface Props<TData extends DataOrErroredCollection> {
  title: string;
  description: string;
  link: {
    text: string;
    href: string;
  };
  children: (data: DataExtract<TData>) => React.ReactNode;
  requestStatus: RequestStatus;
  data?: TData | null;
}

/**
 * Component for use on the homepage to provide the panel containers for each section.
 *
 * Handles loading and error states, and passes successful data to the children to be
 * rendered. (See Homepage.tsx for usage.)
 */
const SectionPanel = <TData extends DataOrErroredCollection>({
  title,
  description,
  link,
  children,
  requestStatus,
  data,
}: Props<TData>): ReactElement => {
  const renderContent = (): React.ReactNode => {
    if (
      (!data && requestStatus === RequestStatus.ERRORED) ||
      (data && Object.values(data).some(dataIsErrored))
    ) {
      return (
        <ErrorWrapper>
          <Icon
            iconName={faExclamationTriangle}
            size={IconSize.small}
            colour={colours.elexonYellow}
          />
          <p>Data failed to load</p>
        </ErrorWrapper>
      );
    }

    if (!data) {
      return <FixedSizeLoadingSpinner isLoading />;
    }

    // By this point we know that there are only Success items in the data so we can safely cast
    // with this in mind
    const extractedData = Object.fromEntries(
      Object.entries(data).map(([k, v]) => [
        k,
        (v as DataSuccess<unknown>).data,
      ])
    ) as DataExtract<TData>;
    return children(extractedData);
  };

  return (
    <PanelWithVerticalMargins
      forwardedAs="section"
      colour={colours.elexonGreen}
    >
      <Header>
        <HorizontalLayout>
          <Title>{title}</Title>
          <InformationTooltip>
            <p>{description}</p>
          </InformationTooltip>
        </HorizontalLayout>
        <StyledLink to={link.href}>{link.text}</StyledLink>
      </Header>
      <MinHeight>{renderContent()}</MinHeight>
    </PanelWithVerticalMargins>
  );
};

export default SectionPanel;
