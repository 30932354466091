import styled from "styled-components";
import fonts from "styles/fonts";

export const NoContentText = styled.p`
  text-align: center;
  font-size: ${fonts.medium};
  font-weight: ${fonts.heavy};

  padding-top: 30px;
`;
