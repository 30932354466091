import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons/faExclamationTriangle";
import { Point } from "@nivo/line";
import Icon from "components/components/Icon/Icon";
import { IconSize } from "components/components/Icon/IconWrapper";
import NivChart from "components/dataVisualization/SystemSellBuyPrices/SystemSellBuyPricesCharts/NivChart";
import SellBuyPricesChart from "components/dataVisualization/SystemSellBuyPrices/SystemSellBuyPricesCharts/SellBuyPricesChart";
import SellBuyPricesTooltip from "components/dataVisualization/SystemSellBuyPrices/SystemSellBuyPricesTooltip/SystemSellBuyPricesTooltip";
import { RequestStatus } from "hooks/useRequest";
import { SettlementMessageModel } from "models/detailedSystemPrices/settlementModels";
import {
  SystemPriceModel,
  SystemPricesDataKey,
} from "models/systemSellAndBuyPrices/systemSellAndBuyPricesModel";
import React, { useMemo } from "react";
import { Link } from "react-router-dom";
import colours from "styles/colours";
import {
  transformNivData,
  transformSystemSellPriceData,
} from "utils/ChartDataUtils/systemSellAndBuyPrices/systemSellAndBuyPrices";
import { getXTimeValuesExtrema } from "utils/chartHelpers";
import { DateFormat, formatDateTimeString } from "utils/dateHelpers";
import routeConstants from "utils/routeConstants";

import {
  HorizontalLayout,
  SettlementMessage,
  SettlementMessagesList,
} from "./styles";

interface Props {
  systemPrices: SystemPriceModel[];
  settlementMessages: SettlementMessageModel[];
}

const NumberOfMessagesToShow = 5;

const SystemSellBuyDataContent: React.FC<Props> = ({
  systemPrices,
  settlementMessages,
}) => {
  const sellBuyData = useMemo(
    () => transformSystemSellPriceData(systemPrices),
    [systemPrices]
  );
  const nivData = useMemo(() => transformNivData(systemPrices), [systemPrices]);

  const allData = [...sellBuyData, ...nivData];
  const { maxXAxisValue, minXAxisValue } = getXTimeValuesExtrema(allData);

  const tooltip = (point: Point): JSX.Element => (
    <SellBuyPricesTooltip
      allChartData={allData}
      minXAxisValue={minXAxisValue}
      maxXAxisValue={maxXAxisValue}
      highlightedPoint={point}
    />
  );

  return (
    <>
      <section aria-label="Active settlement period messages">
        <SettlementMessagesList>
          {settlementMessages
            .slice(0, NumberOfMessagesToShow)
            .map((message) => (
              <SettlementMessage key={message.messageReceivedDateTime}>
                <HorizontalLayout>
                  <Icon
                    iconName={faExclamationTriangle}
                    size={IconSize.medium}
                    colour={colours.elexonOrange}
                  />
                  <h3>Settlement period {message.settlementPeriod}</h3>
                  <span>
                    {formatDateTimeString(
                      new Date(message.messageReceivedDateTime),
                      DateFormat.DateTime
                    )}
                  </span>
                </HorizontalLayout>
                <p>{message.messageText}</p>
              </SettlementMessage>
            ))}
          {settlementMessages.length > 5 && (
            <Link to={routeConstants.settlementMessages}>
              See all settlement messages
            </Link>
          )}
        </SettlementMessagesList>
      </section>
      <section>
        <h2>System Buy / Sell Prices</h2>
        <SellBuyPricesChart
          series={sellBuyData.filter(
            (x) => x.id == SystemPricesDataKey.systemSellPrice
          )}
          dataFetchStatus={RequestStatus.SUCCESSFUL_OR_NOT_STARTED}
          rerequestData={(): void => {}}
          tooltip={tooltip}
        />
        <h2>Net Imbalance Volume</h2>
        <NivChart
          series={nivData.filter(
            (x) => x.id == SystemPricesDataKey.netImbalanceVolume
          )}
          dataFetchStatus={RequestStatus.SUCCESSFUL_OR_NOT_STARTED}
          rerequestData={(): void => {}}
          tooltip={tooltip}
        />
      </section>
    </>
  );
};

export default SystemSellBuyDataContent;
