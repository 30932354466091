import ShowMoreText from "components/shared/ShowMoreText/ShowMoreText";
import styled, { css, FlattenSimpleInterpolation } from "styled-components";
import colours from "styles/colours";
import fonts from "styles/fonts";

export const EventItemContent = styled.li<{ isNew: boolean }>`
  padding: 20px;
  border-radius: 6px;
  width: 100%;
  box-sizing: border-box;
  list-style-type: none;

  ${({ isNew }): false | FlattenSimpleInterpolation =>
    isNew &&
    css`
      background-color: ${colours.jungleGreen}4d;
      box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.1);
      border: 1px solid ${colours.jungleGreen};
    `}
`;

export const NewMessage = styled.div`
  font-size: ${fonts.medium};
  font-weight: bold;
  color: ${colours.elexonBlue};
  padding-bottom: 10px;
`;

export const IconWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 40px;
`;

export const HorizontalLayout = styled.div`
  display: flex;
  gap: 20px;
  width: 100%;
`;

export const Title = styled.h3`
  font-weight: bold;
`;

export const EventInfo = styled.div`
  width: 100%;
`;

export const Timestamp = styled.div`
  font-size: ${fonts.mediumSmaller};
  font-weight: bold;
  margin-bottom: 8px;
`;

export const StyledShowMoreText = styled(ShowMoreText)`
  font-size: ${fonts.smallMedium};
`;
