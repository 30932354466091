import SectionPanel from "components/homepage/SectionPanel/SectionPanel";
import { RequestStatus } from "hooks/useRequest";
import { SettlementMessageModel } from "models/detailedSystemPrices/settlementModels";
import { SystemPriceModel } from "models/systemSellAndBuyPrices/systemSellAndBuyPricesModel";
import React from "react";
import { DataOrErrored } from "utils/dataOrErrored";
import routeConstants from "utils/routeConstants";

import SystemSellBuyPricesContent from "./SystemSellBuyPricesContent";

export type SystemSellBuyPriceData = {
  systemPrices: DataOrErrored<SystemPriceModel[]>;
  settlementMessages: DataOrErrored<SettlementMessageModel[]>;
} | null;

interface Props {
  requestStatus: RequestStatus;
  data: SystemSellBuyPriceData;
}

const SystemSellBuyPrices: React.FC<Props> = ({ requestStatus, data }) => {
  return (
    <SectionPanel
      title="System Buy / Sell Prices"
      description="TODO tooltip #####################################"
      link={{
        text: "See full graph",
        href: routeConstants.systemPrices,
      }}
      requestStatus={requestStatus}
      data={data}
    >
      {({ systemPrices, settlementMessages }): React.ReactNode => (
        <SystemSellBuyPricesContent
          systemPrices={systemPrices}
          settlementMessages={settlementMessages}
        />
      )}
    </SectionPanel>
  );
};

export default SystemSellBuyPrices;
