import styled from "styled-components";
import colours from "styles/colours";
import fonts from "styles/fonts";

export const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

export const RefreshButton = styled.button`
  display: flex;
  align-items: center;
  gap: 6px;
  color: ${colours.elexonBlack};

  background: none;
  border: none;
  border-radius: 2px;
  padding: 0;
  font: inherit;

  &:disabled {
    color: ${colours.mediumGrey};
    cursor: default;
  }

  &:hover:not(:disabled) {
    text-decoration: underline;
    cursor: pointer;
  }
`;

export const RefreshButtonText = styled.span`
  color: ${colours.linkColor};
`;

export const LastUpdatedTextStyle = styled.p`
  font-size: ${fonts.xSmall};

  /* Need enough specificity to override the content css */
  &&& {
    margin-bottom: 0;
  }
`;

export const RefreshErrorContainer = styled.div`
  display: flex;
  align-items: center;

  color: ${colours.darkRed};
  font-size: ${fonts.xSmall};
`;
