import LoadingSpinner from "components/components/LoadingSpinner/LoadingSpinner";
import Panel from "components/shared/Panel/Panel";
import { Link } from "react-router-dom";
import styled from "styled-components";
import fonts from "styles/fonts";

export const PanelWithVerticalMargins = styled(Panel)`
  margin: 8px 0 16px;
`;

export const Header = styled.header`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 20px 20px 0 20px;
`;

export const Title = styled.h2`
  font-size: ${fonts.large} !important;
  padding-left: 20px;
`;

export const StyledLink = styled(Link)`
  font-size: ${fonts.mediumSmaller};
  text-decoration: underline;
  font-weight: bold;
`;

export const HorizontalLayout = styled.div`
  display: flex;
  align-content: center;
`;

export const MinHeight = styled.div`
  min-height: 150px;
`;

export const ErrorWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;

  font-size: ${fonts.medium};
  font-weight: bold;

  padding-top: 30px;
`;

export const FixedSizeLoadingSpinner = styled(LoadingSpinner)`
  height: 70px;
`;
