import {
  faRefresh,
  faExclamationTriangle,
} from "@fortawesome/free-solid-svg-icons";
import Icon from "components/components/Icon/Icon";
import { IconSize } from "components/components/Icon/IconWrapper";
import { RequestStatus } from "hooks/useRequest";
import React, { useCallback, useEffect, useState } from "react";
import {
  formatUTCDuration,
  getDurationFromStartAndEndDatesUTC,
} from "utils/dateHelpers";

import {
  Container,
  RefreshErrorContainer,
  RefreshButton,
  LastUpdatedTextStyle,
  RefreshButtonText,
} from "./style";

const LastUpdatedText = {
  NoData: "no data loaded",
  Updating: "updating...",
  JustNow: "updated just now",
  DurationAgo: (formattedDuration: string): string =>
    `last updated ${formattedDuration} ago`,
};

const getLastUpdatedText = (
  isLoading: boolean,
  lastRefreshedDate?: Date
): string => {
  if (isLoading) {
    return LastUpdatedText.Updating;
  }
  if (!lastRefreshedDate) {
    // Usually the first data fetch will be done on page load so this will not
    // show, however we allow for the initial load being initiated later.
    return LastUpdatedText.NoData;
  }

  const duration = getDurationFromStartAndEndDatesUTC(
    lastRefreshedDate,
    new Date()
  );

  if (Object.values(duration).every((timePeriod) => timePeriod === 0)) {
    return LastUpdatedText.JustNow;
  }
  return LastUpdatedText.DurationAgo(formatUTCDuration(duration));
};

interface Props {
  lastRefreshedDate?: Date;
  requestStatus: RequestStatus;
  triggerDataRefresh: () => Promise<void>;
}

const LastUpdated: React.FC<Props> = ({
  lastRefreshedDate,
  requestStatus,
  triggerDataRefresh,
}) => {
  const isLoading = requestStatus === RequestStatus.IN_PROGRESS;
  const [lastUpdatedText, setLastUpdatedText] = useState(
    LastUpdatedText.NoData
  );
  const updateLastUpdatedText = useCallback(() => {
    // If text has changed, this will force a refresh via lastRefreshedDate
    const newText = getLastUpdatedText(isLoading, lastRefreshedDate);
    setLastUpdatedText(newText);
  }, [isLoading, lastRefreshedDate]);

  useEffect(() => {
    updateLastUpdatedText();
    if (!isLoading) {
      // Note: if page is not the active tab then timers may take longer to
      // fire, but in this case we do not mind since it calculates the text
      // from the current time independently of when it was last called
      const checkTextUpdateTimer = setInterval(() => {
        updateLastUpdatedText();
      }, 10_000);
      return () => clearInterval(checkTextUpdateTimer);
    }
  }, [updateLastUpdatedText, isLoading]);

  return (
    <Container>
      <RefreshButton onClick={triggerDataRefresh} disabled={isLoading}>
        <RefreshButtonText>Refresh</RefreshButtonText>
        <Icon iconName={faRefresh} size={IconSize.mediumSmall} />
      </RefreshButton>
      <LastUpdatedTextStyle data-test-id="last-updated-text" aria-live="polite">
        {lastUpdatedText}
      </LastUpdatedTextStyle>
      {requestStatus === RequestStatus.ERRORED && (
        <RefreshErrorContainer>
          <Icon iconName={faExclamationTriangle} size={IconSize.mediumSmall} />
          <p aria-live="polite">
            Page failed to auto-refresh, please click on refresh
          </p>
        </RefreshErrorContainer>
      )}
    </Container>
  );
};

export default LastUpdated;
