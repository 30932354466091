import SectionPanel from "components/homepage/SectionPanel/SectionPanel";
import { RequestStatus } from "hooks/useRequest";
import { SystemWarningsModel } from "models/systemWarnings/systemWarningsModel";
import React from "react";
import { DataOrErrored } from "utils/dataOrErrored";
import routeConstants from "utils/routeConstants";

import EventsContent from "./EventsContent/EventsContent";

export type EventsData = {
  systemWarnings: DataOrErrored<SystemWarningsModel[]>;
} | null;

interface Props {
  requestStatus: RequestStatus;
  data: EventsData;
}

const Events: React.FC<Props> = ({ requestStatus, data }) => (
  <SectionPanel
    title="Events and System Warnings"
    description="This shows the three most recent events published in the last week."
    link={{
      text: "See all events",
      href: routeConstants.events,
    }}
    data={data}
    requestStatus={requestStatus}
  >
    {({ systemWarnings }): React.ReactNode => (
      <EventsContent systemWarnings={systemWarnings} />
    )}
  </SectionPanel>
);

export default Events;
